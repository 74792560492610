/* Null optional values should not be passed to ga send as subsequent optional values will not register:
"If you want to provide a value only for the second or 3rd optional parameter, you need to pass in undefined for the preceding optional parameter."
https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiEventTracking#_gat.GA_EventTracker_._trackEvent
*/

export class HelpersModule {
    constructor() {
        this.init();
    }

    getQueryVariable(variable) {

        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            const pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    getQueryStringByName(name) {

        name = name.replace("/[\[]/", "\\[").replace("/[\]]/", "\\]");
        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(decodeURIComponent(results[1].replace(/\+/g, " ")));
    }

    getCalculatedDistance(lat1, lon1, lat2, lon2) {

        const radlat1 = Math.PI * lat1 / 180;
        const radlat2 = Math.PI * lat2 / 180;
        const radlon1 = Math.PI * lon1 / 180;
        const radlon2 = Math.PI * lon2 / 180;
        const theta = lon1 - lon2;
        const radtheta = Math.PI * theta / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
         
        return dist;
    }

    getAge(d,m,y){

        const today = new Date(),
            thisYear = today.getFullYear(),
            thisMonth = today.getMonth() + 1,
            thisDate = today.getDate();

        let age = thisYear - y;

        if(thisMonth < m || thisMonth === m && d <= thisDate){
            age--;
        }

        return age;
    }

    breakpoints(vp) {
        var bp = {
            desktop: 991, //960
            tablet: 600,
            mobileNav: 480,
        };
        let output = (window.innerWidth > bp[vp]);
        return output;
    }

    debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    appendTrailingSlash(url) {
        return url += url.endsWith("/") ? "" : "/";
    }

    init() {
    }

}

