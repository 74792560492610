import { HelpersModule } from "../helpers/helpers";
import { UrlHelperModule } from "../helpers/urlHelper";
import SameHeight from 'same-height';

const helpersModule = new HelpersModule();

export class HomeIntroductionPanelModule {

    constructor() {
        this.init();
    }

    navigation() {

        $(".js-home-introduction--menu-item").click(function (e) {
            e.preventDefault();
            var panelRqst = $(this)[0].getAttribute("value");
            $("#component-home-introduction--home").hide();
            $("#component-home-introduction--block-1").hide();
            $("#component-home-introduction--block-2").hide();
            $("#component-home-introduction--block-3").hide();
            $("#" + panelRqst).show();
        });

        $("#component-home-introduction").on('click', '.js-home-introduction__panel', function (e) {
            e.preventDefault();
            var panelRqst = $(this).data("value");
            $("#component-home-introduction--home").hide();
            $("#" + panelRqst).show();
        });

    }


    init() {

        this.navigation();

    }

}