export class ArticlesModule {

    constructor() {
        this.init();
    }

    init() {
		$("#loadMoreArticles").click(function (e) {

			e.preventDefault();

			$(".article-item-hide").nextAll().andSelf().slice(0, 3).removeClass("article-item-hide");

			let remainingHidden = $(".article-item-hide").length;

			if (remainingHidden == 0) {
				$("#loadMoreArticles").hide();
			}

		});
    }

}