export class PeopleGridModule {

    constructor() {

        if ($("#component-people-grid").length > 0) {
            this.properties();
            this.init();
        }
    }

    properties() {
        this.people = [];
    }

    loadRemote() {

        let _this = this;
        let data = {};
        var c_baseUrl = apilocation + "peopleapi/GetPeople";

        $.ajax({
            type: 'get',
            data: data,
            url: c_baseUrl,
            dataType: "json",
            success: function (response) {
                let people = response;
                if (people.length > 0) {
                    _this.populateDropdown(people);
                    _this.loadHandleBars(people);
                }
            }
        });


    }

    loadHandleBars(people) {
        let _this = this;

        var source = $("#people-template").html(),
            template,
            context,
            html;

        if (source) {
            template = Handlebars.compile(source);
            context = { people: people };
            html = template(context);
            $("#people-template").after(html);
        }

    }

    populateDropdown(people) {

        $(".js-people-grid-filter").append($('<option>', {
            value: 0,
            text: "All"
        }));

        $(".js-people-grid-filter").append($('<option>', {
            value: 1,
            text: "Board of Directors"
        }));

        $(".js-people-grid-filter").append($('<option>', {
            value: 2,
            text: "Executive Committee"
        }));

        $(".js-people-grid-filter").on('change', function () {

            let _this = this;
            var chosenItem = $(this).val();
            var chosenText = $(this).find("option:selected").text();

            if (chosenItem == 0) {
                $(".people-grid__item").show();
            }
            else if (chosenItem == 1) {
                $(".people-grid__item").show();
                $.each($(".people-grid__item"), function () {
                    var $teams = $(this).data("teams");
                    if ($teams.indexOf(chosenText) == -1) {
                        $(this).hide();
                    }
                });
            }
            else if (chosenItem == 2) {
                $(".people-grid__item").show();
                $.each($(".people-grid__item"), function () {
                    var $teams = $(this).data("teams");
                    if ($teams.indexOf(chosenText) == -1) {
                        $(this).hide();
                    }
                });
            }




        });

    }

    init() {

        this.loadRemote();  
    }


}