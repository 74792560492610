export class UrlHelperModule {
    constructor() {
        this.mackApi = false;
        this.apilocation = "/uapi/";
        this.init();
    }

    init() {
        var that = this;
        //comment this out on live
        if (location.hostname.indexOf('localhost') > -1 || location.hostname.indexOf(".") === -1) {
            that.apilocation = '/umbraco/api/';
            that.mockApi = true;
        }
    }
}