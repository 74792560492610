import SameHeight from 'same-height';

export class SameHeightHelper {
    constructor() {
        this.init();
    }

    update(attribute) {
        var prevClassName = "";
        var sameHeightElements = $("body").find("[" + attribute + "]");
        sameHeightElements.each(function(i, el) {            
            var css = $(el).data(attribute.replace("data-", ""));          
            if (css !== prevClassName) {
              SameHeight.init( "." + css, "screen and (min-width: 992px)");
            }            
            prevClassName = css;          
        });        
    }

    init() {
        this.update("data-same-height--lg");
        this.update("data-same-height--sm");   
    }
}






