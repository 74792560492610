import { UrlHelperModule } from "../helpers/urlHelper";
import { navigationModule } from "../navigation/navigation";
import { StickyHeaderModule } from "../navigation/stickyHeader";
import { HelpersModule } from "../helpers/helpers";
import { SameHeightHelper } from "../helpers/SameHeightHelper";
import { StockBoxModule } from "../investors/stockbox";
import { StickynavModule } from "../Stickynav/stickynav";
import { DisclaimerModule } from "../disclaimers/disclimer";


export class GlobalLoader {
    constructor() {
        this.init();
    }

    init() {
        navigationModule();
        const stickyHeaderModule = new StickyHeaderModule();
        const urlHelperModule = new UrlHelperModule();
        const helpersModule = new HelpersModule();
        const sameHeightHelper = new SameHeightHelper();
        const stockBoxModule = new StockBoxModule();
        const stickynavModule = new StickynavModule();
        const disclaimerModule = new DisclaimerModule();

        $(document).ajaxStart(function () {
            $("#ajax_loader").css("display", "block");
        });

        $(document).ajaxComplete(function () {
            $("#ajax_loader").css("display", "none");
        });

    }

}




(function ($) {

    $.fn.visible = function (partial) {

        var $t = $(this),
            $w = $(window),
            viewTop = $w.scrollTop(),
            viewBottom = viewTop + $w.height(),
            _top = $t.offset().top,
            _bottom = _top + $t.height(),
            compareTop = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;

        return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

    };

})(jQuery);

var win = $(window);

var allMods = $(".module");

allMods.each(function (i, el) {
    var el = $(el);
    if (el.visible(true)) {
        el.addClass("already-visible");
    }
});

win.scroll(function (event) {

    allMods.each(function (i, el) {
        var el = $(el);
        if (el.visible(true)) {
            el.addClass("come-in");
        }
    });

});



