export class StockBoxModule {
    constructor() {
        this.properties();
        this.init();
    }

    properties() {
        this.stockBox = $(".js-stock-box");
    }

    // TODO 
    loadStockData(_iframe) {
        
        //var iFrameBody;
        //if (_iframe.contentDocument) { // FF
        //    iFrameBody = _iframe.contentDocument.getElementsByTagName('body')[0];
        //}
        //else if (_iframe.contentWindow) { // IE
        //    iFrameBody = _iframe.contentWindow.document.getElementsByTagName('body')[0];
        //}

    }

    readStockData() {
        var url = "//ir2.flife.de/data/spire_healthcare/ticker_home.php";
        var _that = this;
        var _iframe = $(this.stockBox).find("iframe");


        $(_iframe).on('load', function () {
            //alert("loaded");
            _that.loadStockData(this);
        });

        _iframe.attr("src", url);


    }

    init() {
        this.readStockData();
    }

}

