﻿export class ReportTabModule {

    constructor() {   
        
        this.init();
    }

    init() {

        $('ul.tabs li').click(function () {
            var tab_id = $(this).attr('data-tab');

            $('ul.tabs li').removeClass('current');
            $('.tab-content').removeClass('current');

            $(this).addClass('current');
            $("#" + tab_id).addClass('current');
        })

        $(".js-filter").change(function () {
            var year = $(".js-filter option:selected").val();
            $(".content-mobile > div").removeClass('current');
            $(".content-mobile > div").addClass('Hidediv');
            $("#" + year).removeClass('Hidediv');
            $("#" + year).addClass('current');
        });    
      
       
    }
}