// import { HelpersModule } from "../helpers/helpers";

// const helpersModule = new HelpersModule();
// import SameHeight from 'same-height';

var siteNav = {

    init: function () {

        var that = this;

        this.megaMenuContainer = $(".site-header__nav");

        var mouseTimeout;

        $(".site-nav__l2, .site-nav__l3").siblings("a").on("mouseenter", function () {

            $(this).parent().siblings().find(".is-on").removeClass("is-on");

            $(this).addClass("is-on").siblings(".site-nav__l2, .site-nav__l3").addClass("is-on");

            $(".site-nav__l2.is-on--child-selected").removeClass("is-on--child-selected").addClass("is-off--child-selected");

            clearTimeout(mouseTimeout);

        }).on("mouseleave", function () {
            var that = this;

            mouseTimeout = setTimeout(function () {
                $(that).removeClass("is-on").siblings(".site-nav__l2, .site-nav__l3").removeClass("is-on");
                $(".site-nav__l1 > .site-nav__list > .site-nav__list__item.is-selected > a").addClass("is-selected");

                $(".site-nav__l2.is-off--child-selected").removeClass("is-off--child-selected").addClass("is-on--child-selected");

            }, 500);
        });

        $(".site-nav__l2, .site-nav__l3").on("mouseenter", function () {

            clearTimeout(mouseTimeout);

        }).on("mouseleave", function () {
            var that = this;

            mouseTimeout = setTimeout(function () {
                $(that).removeClass("is-on").siblings("a").removeClass("is-on");
                $(".site-nav__l1 > .site-nav__list > .site-nav__list__item.is-selected > a").addClass("is-selected");

                $(".site-nav__l2.is-off--child-selected").removeClass("is-off--child-selected").addClass("is-on--child-selected");

            }, 500);
        });

        that.selectedStates();

        if ($('#js-hospital-area').length > 0) {
            $('.is-on--child-selected  .site-nav__list > li:first-child').after('<li class="site-nav__list__item is-selected"><a href="/spire-fylde-coast-hospital/treatments/bloodtest/"> > ' + $('h1.heading-l').text().trim() + '</a></li>');
        }

        this.megaMenuContainer.addClass("js");

    },
    selectedStates: function () {

        var currentPath = window.location.href,
            selectedItem = $(".js-site-nav").find("a[href='" + currentPath + "']").parent(".site-nav__list__item, .site-nav__column__item");

        selectedItem.addClass("is-selected");

        selectedItem.parents(".site-nav__l2").addClass("is-on--child-selected")
            .parent(".site-nav__list__item").addClass("is-selected").children("a").addClass("is-selected");

    }
};

var siteNavMobile = {
    openNav: function () {
        //$(".sidenav--wrapper").addClass("show").find("#mySidenav").addClass("show");
        $("#mySidenav").addClass("show-side-nav");
    },
    closeNav: function () {
        $("#mySidenav").removeClass("show-side-nav");
    },
    init: function () {
        var _this = this;
        var $navElements = $(".js-site-nav-mobile, .js-site-nav-mobile-toggle");
        var $contactElements = $(".js-contact-mobile-toggle, .js-contact-mobile");
        var $expandableNavItems = $(".site-nav-mobile__l1 ul li").has(".site-nav-mobile__l2 ul li").children('a');
        var $searchElements = $(".js-site-search-mobile-toggle, .js-site-search-mobile");
        var $nav = $(".sidenav");
        //$(".sidenav--wrapper").hide();

        var backgroundImg = $(".hero-home__image--mobile").attr("style");
        if (backgroundImg === undefined) {
        }
        else {
            var url = backgroundImg.replace(/(url\(|\)|'|")/gi, '');
            url = url.trim().replace("background-image:", "");
            if (url.length > 0) {
                //$(".js-site-header-mobile").addClass('remove-background');
            }
        }

        $expandableNavItems.addClass('hasItems');

        //$(document).on("click", function (e) {
        //    if (!$(e.target).closest(".sidenav").length) {
        //        console.log("in here");
        //        if ($nav.hasClass("show")) {
        //            _this.closeNav();
        //        }
        //    }
        //});

        $($expandableNavItems).on("click", function () {
            $(this).toggleClass("is-on");
            //$expandableNavItems.toggleClass('is-on');
        });

        // Burger Menu Clicked.
        $(".js-site-nav-mobile-toggle").on("click", function (e) {
            e.preventDefault();
            console.log("Open");
            _this.openNav();

            //$searchElements.removeClass("is-on");
            //$contactElements.removeClass("is-on");

            //if ($navElements.hasClass("is-on")) {
            //    $navElements.removeClass("is-on");
            //} else {
            //    $navElements.addClass("is-on");
            //    $contactElements.removeClass("is-on");
            //}
        });

        $(".closebtn").on("click", function (e) {
            console.log("Close");
            _this.closeNav();
        });

        // removed as causing an issue with navigation, needs further investigation as to what this was for.
        //var mobilebackground = $('.hero-home__image--mobile').attr("style");
        //if (mobilebackground.includes("url('')")) {
        //    $(".js-contact-mobile-toggle").removeClass("show-tab");
        //}
        //else {
        //    $(".js-contact-mobile-toggle").addClass("show-tab");
        //}

        // Search Menu Clicked
        $(".js-site-search-mobile-toggle").on("click", function (e) {

            e.preventDefault();

            $contactElements.removeClass("is-on");
            $navElements.removeClass("is-on");

            if ($searchElements.hasClass("is-on")) {
                $searchElements.removeClass("is-on");
            } else {
                $searchElements.addClass("is-on");
            }
        });

        // Contact Phone Icon clicked.
        $(".js-contact-mobile-toggle").on("click", function (e) {
            e.preventDefault();

            $searchElements.removeClass("is-on");
            $navElements.removeClass("is-on");

            if ($contactElements.hasClass("is-on")) {
                $contactElements.removeClass("is-on");
            } else {
                $contactElements.addClass("is-on");
                $navElements.removeClass("is-on");
            }
        });


        // old mega menu    
        $(".site-nav-mobile__l2").siblings("a").on("click", function (e) {
            var $item = $(this).siblings(".site-nav-mobile__l2");

            if ($item.find(".site-nav-mobile__list").children().length > 0) {
                e.preventDefault();
                if ($item.hasClass("is-on")) {
                    $(this).siblings(".site-nav-mobile__l2").removeClass("is-on");
                } else {
                    $(this).siblings(".site-nav-mobile__l2").addClass("is-on");
                }
            }
        });

        // new mega menu
        $(".site-nav-mobile__l2").siblings("a").on("click", function (e) {
            var $item = $(this).siblings(".site-nav-mobile__l2");

            if ($item.find(".mega-menu__items").children().length > 0) {
                e.preventDefault();
                if ($item.hasClass("is-on")) {
                    $(this).siblings(".site-nav-mobile__l2").removeClass("is-on");
                } else {
                    $(this).siblings(".site-nav-mobile__l2").addClass("is-on");
                }
            }
        });
    }
};

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

//var siteHeaderLocations = {


//    init: function () {

//        var $toggle = $(".js-toggle-site-header__locations"),
//            $target = $(".js-site-header__locations"),
//            $header = $('.site-header'),
//            $headerTop = $('.site-header__top'),
//            $nav = $('.site-nav__bar'),
//            $topMenuList = $(".site-header__top-menu__list"),
//            $topNav = $(".js-top-nav");

//        $toggle.on("click", function (e) {
//            e.preventDefault();
//            if ($(this).hasClass('is-on')) {
//                $(this).removeClass('is-on');
//                $target.removeClass('is-on');
//                $headerTop.removeClass('is-filled');
//                $nav.removeClass('is-filled');
//                $topMenuList.removeClass("site-header__top-menu__list--select-hospital");
//            } else {
//                $topNav.find(".navigation a").removeClass("selected");
//                $topNav.find(".content").hide();
//                $(this).addClass('is-on');
//                $target.addClass('is-on');
//                $headerTop.addClass('is-filled');
//                $nav.addClass('is-filled');
//                $topMenuList.addClass("site-header__top-menu__list--select-hospital");
//            }
//        });

//        $topNav.find(".navigation a").click(function (e) {
//            var rel = $(this).attr('rel');
//            $topNav.find(".content").hide();

//            $topNav.find(".navigation a").removeClass("selected");
//            $(this).addClass("selected");

//            $topNav.find(".footer").hide();

//            $(rel).show();
//        });

//        $topNav.find(".body a").hover(function () {

//            var data = $(this).parent().find(".data");
//            var infinityNumber = $(this).parent().find(".InfinityNumber");
//            var hospital = $(data).data("hospital");
//            var address = $(data).data("address");
//            var telephone = infinityNumber.text();
//            var footerPanel = $(this).parent().parent().parent().find(".footer");

//            $(footerPanel).find(".hospital").text(hospital + ",");
//            $(footerPanel).find(".address").html(address);
//            var telA = $(footerPanel).find(".telephone > a");
//            $(telA).find("span").text(telephone);

//            $(footerPanel).show();
//        }, function () {
//            //$(this).parent().parent().parent().find(".footer").hide();
//        });

//        $(document).click(function (e) {
//            if (!($(e.target).closest(".js-site-header__locations").length)) {

//                if (!e.target.closest(".locations-button")) {
//                    if ($(".js-toggle-site-header__locations").hasClass("is-on")) {
//                        $(".js-toggle-site-header__locations").trigger("click");
//                    }
//                }

//            }
//        });


//    }
//};

//var topMenu = {
//    config: {
//        $menu: $(".js-site-header__top-menu")
//    },
//    init: function () {
//        var c = this.config,
//            pageFound = false;

//        /*  c.$menu.find(".js-site-header__top-menu__item").each(function () {
//              var path = window.location.href;
//              path = path.substring(path.indexOf("//"));
//              if (path.indexOf($(this).children("a").attr("href")) === 0) {
//                  $(this).addClass("is-selected");
//                  pageFound = true;
//              }
//          }); */
//    }
//};

function navigationModule() {

    siteNav.init();

    $(window).load(function () {
        siteNavMobile.init();
        //siteHeaderLocations.init();
        //topMenu.init();
    });
}

export { navigationModule };