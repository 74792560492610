import { VideoModalModule } from "../modals/video";
import { CarouselHomeModule } from "../Carousels/home";
import { AccordionSectionTextModule } from "../Accordions/section-text";
import { HomeIntroductionPanelModule } from "../introductionPanel/homeIntroductionPanel";
import { CarouselPeopleModule } from "../Carousels/people";
import { PeopleGridModule } from "../Grids/peopleGrid";
import { NewsModule } from "../news/news";
import { EventsModule } from "../events/events";
import { EnquiryFormModule } from "../enquiryforms/enquiryforms.js";
import { ReportTabModule } from "../reports/reportTabs.js"
import { ArticlesModule } from "../articles/articles.js"

export class NestedLoader {
    constructor() {
        this.init();
    }

    loadHome() {
        const videoModal = new VideoModalModule();  
        const carouselHomeModule = new CarouselHomeModule();
        const homeIntroductionPanelModule = new HomeIntroductionPanelModule();

        if (videoModal == null) {
            console.log("videoModal - Not loaded");
        }
        if (carouselHomeModule == null) {
            console.log("carouselHomeModule - Not loaded");
        }
        if (homeIntroductionPanelModule == null) {
            console.log("homeIntroductionPanelModule - Not loaded");
        }
    }

    loadGeneric() {
        const videoModal = new VideoModalModule();  
        const accordionSectionText = new AccordionSectionTextModule();
        const peopleGridModule = new PeopleGridModule();
        const reportTabModule = new ReportTabModule();
        const articlesModule = new ArticlesModule();

        if (videoModal == null) {
            console.log("videoModal - Not loaded");
        }

        if (accordionSectionText == null) {
            console.log("accordionSectionText - Not loaded");
        }

        if (peopleGridModule == null) {
            console.log("peopleGridModule - Not loaded");
        }

        if (reportTabModule == null) {
            console.log("reportTabModule - Not loaded");
        }

        if (articlesModule == null) {
            console.log("articlesModule - Not loaded");
        }
    }

    loadPerson() {
        const carouselPeopleModule = new CarouselPeopleModule();

        if (carouselPeopleModule == null) {
            console.log("carouselPeopleModule - Not loaded");
        }
    }

    loadNews() {
        const newsModule = new NewsModule();

        if (newsModule == null) {
            console.log("newsModule - Not loaded");
        }
    }

    loadEvents() {
        const eventsModule = new EventsModule();

        if (eventsModule == null) {
            console.log("eventsModule - Not loaded");
        }
    }

    LoadEnquiryForms() {
        const enquiryFormModule = new EnquiryFormModule();

        if (enquiryFormModule == null) {
            console.log("enquiryFormModule - Not loaded");
        }
    }

    init() {

        let bodyElement = document.getElementsByTagName('body')[0];
        let dataType = bodyElement.dataset.dtype;

        console.log(dataType);

        if (dataType === 'home') this.loadHome();

        if (dataType === 'generic') this.loadGeneric();

        if (dataType === 'person') this.loadPerson();

        if (dataType === 'news') this.loadNews();

        if (dataType === 'events') this.loadEvents();

        if (dataType === 'enquiryForm') this.LoadEnquiryForms();

        if (dataType === 'enquiryFormThankYou') this.LoadEnquiryForms();
    }
}