import { HelpersModule } from "../helpers/helpers";
const helpersModule = new HelpersModule();

export class StickyHeaderModule {
    constructor() {
        this.documentHeight = $(document).height();
        //if (this.documentHeight > 1400) {
        //    this.init();
        //}
        this.init();
    }

    properties() {
        this.siteHeader = $('.js-site-header');
        this.mobileHeader = $(".js-site-header-mobile");
        this.mobileStickyClass = "site-header-mobile--sticky";
        this.siteContainer = $(".site-container");
        this.siteHeader.attr('id', 'js-site-header');
        this.siteHeader.removeClass("is-sticky");
        this.siteContainer.removeClass("is-sticky-header").removeAttr("style");
    }


    down() {
        var _this = this;
        _this.siteContainer.addClass("is-sticky-header").css({ paddingTop: _this.siteHeader.find(".site-header__fixed").height() });
        $(".site-header__nav").hide();
        _this.siteHeader.addClass("is-sticky");
        _this.mobileHeader.addClass("is-sticky");
        $(".treatment-contact-us-bar").css({ 'position': 'fixed', 'top': '80px', 'width': '100%' });
        $(".anchor").addClass('is-sticky');

    }

    up() {
        var _this = this;
        $(".site-header__nav").show();
        _this.siteHeader.removeClass("is-sticky");
        _this.siteContainer.removeClass("is-sticky-header").removeAttr("style");
        _this.mobileHeader.removeClass("is-sticky");
        $(".treatment-contact-us-bar").css({ 'position': 'relative', 'top': 'auto', 'width': 'auto' });
        $(".anchor").removeClass('is-sticky');
    }

    setup() {

        var _this = this;
        // New sticky nav process

        // Added to force clearing the sticky nav when the scroll position is 0 as sometimes it gets stuck with the waypoint process.
        window.addEventListener("scroll", function (event) {
            if (this.scrollY == 0) {
                //console.log("Cleared sticky");
                _this.up();
            }
        });


        _this.waypoint = new Waypoint({
            element: $(".site-header__waypoint")[0],
            handler: function (direction) {


                if (direction === "down") {
                    //console.log("Scrolling down");
                    //_this.siteHeader.addClass("is-sticky");
                    _this.down();
                    //_this.mobileHeader.addClass(_this.mobileStickyClass);
                }
                else if (direction === "up") {
                    //console.log("Scrolling up");
                    //_this.siteHeader.removeClass("is-sticky");
                    _this.up();
                    //_this.mobileHeader.removeClass(_this.mobileStickyClass);
                }



            },
            offset: 0
        });
        // End new sticky nav process

        /* start */
        //var _this = this;

        //if (helpersModule.breakpoints("desktop")) {
        //    _this.waypoint = new Waypoint({
        //        element: $(".site-header__waypoint--desktop")[0],
        //        handler: function (direction) {

        //            console.log("I am scrolling Desktop");

        //            if (direction === "down") {
        //                _this.down();
        //            }
        //            if (direction === "up") {
        //                _this.up();
        //            }

        //        },
        //        offset: -150
        //    });

        //} else {
        //    if (_this.mobileHeader.length > 0) {

        //        var waypointMobile = new Waypoint({
        //            element: $(".site-header__waypoint--mobile")[0], //_this.mobileHeader[0],
        //            handler: function (direction) {
        //                console.log("I am scrolling Mobile");
        //                if (direction === "down") {
        //                    _this.mobileHeader.addClass(_this.mobileStickyClass);
        //                    //_this.mobileHeader.removeClass('remove-background');                            
        //                    _this.siteContainer.addClass("site-container--mobile-padding");
        //                } else if (direction === "up") {
        //                    _this.mobileHeader.removeClass(_this.mobileStickyClass);
        //                    //_this.mobileHeader.addClass('remove-background');
        //                    _this.siteContainer.removeClass("site-container--mobile-padding");
        //                }

        //            },
        //            offset: -70
        //        });

        //    }
        //}
        /* end */

        /* start */
        var $toggles = $(".js-toggle-site-header__locations, .js-accordion__cookie");

        $toggles.on('click', function (e) {
            e.preventDefault();
            setTimeout(function () { Waypoint.refreshAll(); }, 0);
        });

        //  var $enqBtn = $('.call-back-request');

        $('.call-back-request').on('click', function () {
            $(".site-container").css('paddingTop', '0');
            $(".js-site-header").removeClass('is-sticky');
            _this.waypoint.disable();
        });

        $(".js-enquiry-toggle").on('click', function () {
            _this.waypoint.enable();
        });

        /* end */

    }

    init() {
        this.properties();
        this.setup();
    }
}










