export class AccordionSectionTextModule {
    constructor() {

        if ($(".container-component__section-text").length > 0) {
            this.init();
        }
    }

    applyCssOnMobile() {
        const mq = window.matchMedia("(min-width: 480px)");

        if (mq.matches) {
            $('.container-component__section-text').removeClass('container-component__section-text--mobile');
        } else {

            $(".container-component__section-text").each(function (index) {

                if (index >= 2) {
                    $(this).addClass('container-component__section-text--mobile');
                }
            });
        }
    }

    hideContent() {
        let elContent = '.container-component__section-text--mobile .section-text__content';
        if ($(elContent).is(":hidden")) {
            $(elContent).show();
        } else {
            $(elContent).hide();
        }
    }

    bindClick() {

        let elHeading = '.container-component__section-text--mobile .section-text__heading';

        $(document).on("click", elHeading, function () {

            let currentContent = $(this).next('.section-text__content');
            let isHidden = currentContent.is(":hidden");

            if (isHidden) {
                $(this).next('.section-text__content').show();
                $(this).addClass('section-text__heading--active');
            } else {
                $(this).next('.section-text__content').hide();
                $(this).removeClass('section-text__heading--active');
            }
        });
    }

    registerResizeHandler() {
        window.onresize = this.onResize.bind(this);
    }

    onResize() {
        this.applyCssOnMobile();
    }

    init() {
        this.applyCssOnMobile();
        this.bindClick();
        this.registerResizeHandler();
    }
}






