export class EnquiryFormModule {

    constructor() {
        this.init();
    }

    getParsleyConfig() {
        return {
            errorsContainer: function (pEle) {
                var $err = pEle.$element.parents('.js-enquiry-form-element').find('.error');
                return $err;
            },
            classHandler: function (el) {
                return el.$element.closest('.js-enquiry-form-element');
            },
            uiEnabled: true
        };
    }

    emailValidator() {

        window.Parsley.addValidator('customequalto', {
            requirementType: 'string',

            validateString: function (value, elem, parsleyInstance) {

                $(elem).on("keyup", _.debounce(function () {
                    parsleyInstance.validate();
                }, 100));

                if (value.length > 0 && $(elem).length > 0) {
                    return value.toLowerCase() === $(elem).val().toLowerCase();
                }
                return false;
            }
        });
    }

    setCookie() {
        var d = new Date();
        d.setTime(d.getTime() + (60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = 'enquiryformthankyou' + "=" + expires + "; " + expires + "; path=/";
        console.log("Cookie read from thank you page: " + document.cookie);
    }

    getCookie() {
        var name = 'enquiryformthankyou' + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    submitForm(thisForm) {
        var that = this;
        var form = $(thisForm).parsley(that.getParsleyConfig());

        if (form.validate()) {
            var enquiryFields = this.generateFieldValues(thisForm);

            $.ajax({
                type: "POST",
                url: "/umbraco/api/EnquiryFormApi/SendEnquiry",
                data: JSON.stringify(enquiryFields),
                contentType: "application/json; charset-utf-8",
                dataType: "json",
                success: function (data) {
                    that.setCookie();
                    var d = new Date();
                    // if the thank you page does not show, maybe the api is taking too long so you might need to look at the following period.
                    d.setTime(d.getTime() + (50 * 1000));
                    var currentTime = d.toUTCString(); //date.getHours() + ':' + date.getMinutes();
                    $("#thankyou").find("#token").val(currentTime);
                    $("#thankyou").submit();
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    alert("Status: " + textStatus); alert("Error: " + errorThrown);
                }
            });
        }
    }

    generateFieldValues(thisForm) {

        var enquiryType = thisForm.find('#typeofenquiry option:selected').text();
        var enquiry = thisForm.find('#enquiry').val();
        var title = thisForm.find('#title option:selected').text();
        var firstname = thisForm.find('#firstname').val();
        var surname = thisForm.find('#surname').val();
        var companyname = thisForm.find('#companyname').val();
        var emailEnquiry = thisForm.find('#email-enquiry').val();
        var enquiryEmailConfirm = thisForm.find('#enquiry-email-confirm').val();
        var phoneNumber = thisForm.find('#phonenumber').val();
        var postcode = thisForm.find('#postcode').val();
        var optinEmail = thisForm.find("input[name='optinEmail']").prop("checked");
        var optinSMS = thisForm.find("input[name='optinSMS']").prop("checked");
        var optinPrivacy = thisForm.find("input[name='optinPrivacy']").prop("checked");
        var showMarketing = thisForm.find('#showMarketing').val();

        var enquiryFields = {
            "enquiryType": enquiryType,
            "enquiry": enquiry,
            "title": title,
            "firstname": firstname,
            "surname": surname,
            "companyname": companyname,
            "emailAddress": emailEnquiry,
            "emailAddressConfirm": enquiryEmailConfirm,
            "phoneNumber": phoneNumber,
            "postcode": postcode,
            "enquiryFormOptinSMS": optinSMS,
            "enquiryFormOptinEmail": optinEmail,
            "enquiryFormOptinPrivacy": optinPrivacy,
            "showMarketing": showMarketing
        };
        //console.log(enquiryFields);
        return enquiryFields;
    }


    init() {
        var that = this;
        that.emailValidator();

        $(".js-enquiry-submit").click(function (e) {
            e.preventDefault();
            var thisForm = $(this).parents(".js-enquiry-form");
            that.submitForm(thisForm);
        });

        if ($("body").data("dtype") == "enquiryFormThankYou") {

            var cookie = that.getCookie();
            if (cookie.indexOf("enquiryformthankyou") == 0) {                
                window.location = "/enquiry";
            }

        }
    }

}