export class DisclaimerModule {

    constructor() {
        this.init();
        this.clickEvents();
    }

    urlParam(name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)')
            .exec(window.location.href);
        if (results == null) {
            return 0;
        }
        return results[1] || 0;
    }

    init() {

        var url = this.urlParam("rtnurl");
        var name = this.urlParam("name");
        var decodedName = decodeURIComponent(name);

        $(".js-disclaimer-link").attr("href", url);
        $(".js-disclaimer-link").text("Continue to " + decodedName);

    }

    clickEvents() {
        var _this = this;
        $(".js-disclaimer-link").click(function (e) {
            _this.setCookie("disclaimerAgreed", true, 1);
        });
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
    }

}