export class StickynavModule {

    constructor() {

        if ($("#navigation-sticky").length > 0) {
            this.properties();
            this.init();
            this.setSelected(document.querySelector(this.navigationSelector));
        }
    }

    properties() {
        this.navigationSelector = '#navigation-sticky .navbar-nav a';
        this.navBarHeight = 145;
    }
    
    distanceToScreenCentre(elem, centre) {
        let rect = elem.getBoundingClientRect();
        let topDist = Math.abs(rect.top - (centre - 5));
        let botDist = Math.abs(rect.bottom - (centre + 5));
        return topDist > botDist ? botDist : topDist;
    }

    scroll() {
        this.selectCurrentSection();
    }

    selectCurrentSection() {
        let _this = this;
        
        let items = document.querySelectorAll(this.navigationSelector);

        let centre = ((window.innerHeight || document.documentElement.clientHeight) / 2) + this.navBarHeight;
        
        let closest = {};
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let el = getElementFromItemData(item);
            
            let dist = this.distanceToScreenCentre(el, centre);
            if(closest.dist === undefined || dist <= closest.dist) {
                closest.dist = dist;
                closest.item = item;
            }
        }
        
        this.setSelected(closest.item);
        
        function getElementFromItemData(item) {
            return document.getElementById(item.dataset.component);
        }
    }
    setSelected(el) {
        $(this.navigationSelector).removeClass("is-selected");
        el.classList.add("is-selected");
    }

    registerScrollListener() {

        let _this = this;

        var raf = window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            window.oRequestAnimationFrame;
        var $window = $(window);
        var lastScrollTop = $window.scrollTop();

        if (raf) {
            loop();
        }

        function loop() {
            var scrollTop = $window.scrollTop();
            if (lastScrollTop === scrollTop) {
                raf(loop);
                return;
            } else {
                lastScrollTop = scrollTop;

                // fire scroll function if scrolls vertically
                _this.scroll();
                raf(loop);
            }
        }
    }

    registerClick() {
        let that = this;

        $(document).on('click', ".nav-link.nav-sticky", function () {
            $("#navigationStickyMobileList").removeClass("show");
            let name = "ac" + this.dataset.component;
            let pos= document.querySelector('[name="' + name + '"]');
            pos.scrollIntoView();
            event.preventDefault()
        });
        
        $(document).on('click', "#stickyNavToggle", function () {
            $("#navigationStickyMobileList").toggleClass("show");
        });
    }

    init() {
        this.registerScrollListener();
        this.registerClick();
    }
}






