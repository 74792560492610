//import { UrlHelperModule } from "./helpers/urlHelper";

export class NewsModule {

    constructor() {
        this.init();
    }

    init() {
        this.events();

        var newsType = $(".js-filter option:selected").val();
        var sorterType = $(".js-sorter option:selected").val();
        this.getNews(newsType + "," + sorterType);
    }

    urlParam(name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)')
            .exec(window.location.href);
        if (results == null) {
            return 0;
        }
        return results[1] || 0;
    }

    filterData() {
        var newsType = $(".js-filter option:selected").val();
        var sorterType = $(".js-sorter option:selected").val();

        //var fNewsType = { 'filterType': newsType, 'filterSorter': sorterType };
        //window.history.pushState(fNewsType, 'news', 'http://www.investors.localhost/news/news/')

        this.getNews(newsType + "," + sorterType);
    }

    events() {
        let _this = this;

        $(".js-filter").change(function () {
            _this.filterData();
        });

        $(".js-sorter").change(function () {
            _this.filterData();
        });

    }

    getNews(filter) {

        if (filter == undefined) {
            filter = "";

            $("#newsType").val("");
            $("#sorter").val("");
        }

        let r_baseUrl = apilocation + "NewsApi/Get?filter=" + filter;
        var dataContainer = $(".js-news-list");
        var listingContainer = $(dataContainer).find(".news--list--results");
        var output = "";

        $(dataContainer).pagination({
            hideWhenLessThanOnePage: true,
            dataSource: r_baseUrl,
            ajax: {
                contentType: null
            },
            locator: 'Rows',            
            totalNumberLocator: function (response) {
                return response.TotalRows;
            },
            //filterItems: function (response) {
            //    console.log(response.Categories);
            //    return response.Categories;
            //},
            prevText: 'Previous',
            nextText: 'Next',
            pageSize: 10,            
            callback: function (data, pagination) {

                output = "";

                var i = 0;
                data.forEach(element => {
                    
                    var image = "";
                    if ((element.Thumbnail != undefined) && (element.Thumbnail != "")) {
                        image = `<img src='${element.Thumbnail}' class='img-fluid'/>`;
                    }

                    var rowCss = i & 1 ? 'odd' : 'even;'

                    var col1Css = '';
                    var col2Css = '';
                    var col3Css = '';

                    if (image == "") {
                        col1Css = "col-2 col-md-1 col-lg-1 order-1";
                        col2Css = "col-10 col-md-11 col-lg-11 order-2";
                        col3Css = "d-none";
                    }
                    else {
                        col1Css = "col-2 col-md-1 col-lg-1 order-2 order-md-1";
                        col2Css = "col-10 col-md-8 col-lg-8 order-3 order-md-2";
                        col3Css = "col-12 col-md-3 col-lg-3 order-1 order-md-3";
                    }


                    var row = `

                        <div class='row news ` + rowCss + `'>
                            <div class='col-2 ` + col1Css + `'>
                                <div class="date date--desktop">
                                    <div class="date--header">${element.Month}</div>
                                    <div class="date--body">
                                        <div class="date--body--day">${element.Day}</div>
                                        <div class="date--body--year">${element.Year}</div>
                                    </div>
                                </div>
                            </div>
                            <div class='news--body ` + col2Css + `'>
                                <h2>${element.Title}</h2> 
                                <p>
                                    ${element.StandFirst}
                                </p>
                                <span>
                                    <i class="fas fa-long-arrow colour-teal"></i>
                                    <a href='${element.Url}'>Read more <i class="fa-ml fal fa-long-arrow-right"></i></a>
                                </span>

                             
                            </div>
                            <div class='thumbnail ` + col3Css + `'>`
                                + image + 
                            `</div>
                        </div>

                    `
                    output += row;
                    i++;

                });

                listingContainer.html(output);

            }

        });

        

    }

}

