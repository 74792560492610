export class CarouselPeopleModule {

    constructor() {

        if ($("#component-people-carousel").length > 0) {
            this.properties();
            this.init();
        }
    }

    properties() {
        this.sliderSelector = "#component-people-carousel .people-carousel__slider";
        this.sliderItemSelector = "#component-people-carousel .people-carousel__item";
        this.initialSlide = 0;
        this.people = [];
        this.nodeId = $("body").data("content");
        this.documentType = $("body").data("dtype");
        this.contentId = $("body").data("content");
        this.refreshTimeInMinutes = 5;
        //this.usePicker = $("span.consultantpicker").data("usepicker");
        //this.hidePractisingAt = $("span.consultantpicker").data("hidepractisingat");
        this.disableLocalStorage = false;
    }

    loadRemote() {
        let _this = this;

        $(this.sliderItemSelector).slick('slickRemove');
        var c_baseUrl = apilocation + "peopleapi/GetPeople";

        let data = {};

        $.ajax({
            type: 'get',
            data: data,
            url: c_baseUrl,
            dataType: "json",
            success: function (response) {
                let people = response;
                if (people.length > 0) {
                    _this.loadHandleBars(people);   
                }
            }
        });

    }

    

    loadHandleBars(people) {
        let _this = this;

        if (people === null || people.length === 0) {
            $("#component-people-carousel").hide();
            return;
        }

        var source = $("#people-template").html(),
            template,
            context,
            html;

        if (source) {
            template = Handlebars.compile(source);
            context = { people: people };
            html = template(context);
            $("#people-template").after(html);
        }

        setTimeout(function () {
            $('#component-people-carousel').show();
            _this.loadSlick();
            _this.addImageLiquid();
            _this.equalHeights();
            _this.bindSlickArrowClick();

        },
        300);

    }

    loadSlick() {

        let _this = this;
        $(this.sliderSelector).slick({
            slidesToScroll: 1.1,
            slidesToShow: 1,
            initialSlide: 0,
            arrows: false,
            dots: false,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 300,
                    settings: {
                        dots: false,
                        infinite: false,
                        slidesToShow: 1.1,
                        slidesToScroll: 1,
                        arrows: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        dots: false,
                        infinite: false,
                        slidesToShow: 2.2,
                        slidesToScroll: 2,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        dots: false,
                        infinite: false,
                        slidesToShow: 3.2,
                        slidesToScroll: 3,
                        arrows: true
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        dots: false,
                        infinite: false,
                        slidesToShow: 3.2,
                        slidesToScroll: 1,
                        arrows: true
                    }
                }
            ]
        });

        $(this.sliderSelector).on('afterChange', function () {
            _this.addOpacity();
        });

        _this.addOpacity();
    }

    addImageLiquid() {
        $('.people-carousel__image').imgLiquid({
            horizontalAlign: '74%'
        });
    }

    equalHeights() {
        $(".people-carousel__slider").each(function () {
            var highestBox = 0;

            $(".people-carousel__panel", this).each(function () {
                if ($(this).height() > highestBox) {
                    highestBox = $(this).height();
                }
            });

            $('.people-carousel__panel', this).height(highestBox);
            $('.people-carousel__gradient--right').show().height(highestBox + 20);
            $('.people-carousel__gradient--left').height(highestBox + 20);
        });
    }

    /* bind arrow click */
    bindSlickArrowClick() {
        let _this = this;
        $(document).on('click', "#component-people-carousel .slick-arrow", function () {
            _this.updateGradientIfSlickIsDisabled();
        });
    }

    addOpacity() {

        $(".people-carousel__item").removeClass("people-carousel__opacity");
        let index = 0;
        $(".people-carousel__item.slick-active").each(function () {

            if (index === 2) {
                $(this).addClass("people-carousel__opacity");
            }

            index++;
        });
    }

    init() {
        this.loadRemote();  
    }


}